import React from "react";

const Skills = () => {
  const skillsContent = [
    {
      id: 1,
      colClass: "left",
      title: "Programming Languages / Frameworks",
      content: [
        " C# / .NET Framework",
        " Java / Spring",
        " Ruby on Rails",
        " Python / Flask / Jupyter",
        " React / Node.js"
      ],
    },
    {
      id: 2,
      colClass: "right",
      title: "Databases",
      content: [
        " DynamoDB",
        " Microsoft SQL Server",
        " Oracle",
        " PostgreSQL",
        " MySQL",
      ],
    },
    {
      id: 3,
      colClass: "rightend",
      title: "Languages",
      content: [
        " English (native)",
        " Spanish (native)",
        " French (elementary)",
        " Czech (beginner)",
      ],
    },
  ];

  return (
    <>
      {skillsContent.map((item) => (
        <div className={item.colClass} key={item.id}>
          <div className="tokyo_section_title">
            <h3>{item.title}</h3>
          </div>
          <div className="tokyo_tm_skill_list">
            <ul>
              {item.content.map((val, i) => (
                <li key={i}>
                  <span>
                    <img
                      className="svg"
                      src="assets/img/svg/rightarrow.svg"
                      alt="arrow"
                    />
                    {val}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default Skills;
