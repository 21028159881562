import React from "react";

const Intro = () => {
  const introContent = {
    imageSrc: "assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1400.png",
    imageSrcSet: "assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_200.png 200w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_343.png 343w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_470.png 470w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_564.png 564w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_645.png 645w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_726.png 726w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_796.png 796w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_860.png 860w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_925.png 925w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_975.png 975w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1028.png 1028w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1083.png 1083w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1131.png 1131w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1180.png 1180w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1228.png 1228w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1279.png 1279w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1321.png 1321w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1368.png 1368w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1385.png 1385w, assets/img/slider/bonillovi/bonillovi_idrtxb_c_scale,w_1400.png 1400w",
    name: "Christopher Luis Bonilla",
    designation: "Seasoned Software Engineer ➙ Forte in Back-End Development with Full-Stack Expertise ➙ Pilots Platform, Portal, People & Program Management to drive Organizational Growth",
    text: (
      <>
        <p>
          Experienced Agile Scrum certified software engineer with a strong background in back-end web development that successfully drives organizational growth. 
          Seasoned in seamlessly integrating project and platform management skills with business acumen to continually improve the software development cycle. 
          Expertise in delivering tailored software and scripting solutions that steer every project towards the company’s goal of operational and technical excellence. 
          Well-versed in front-end development with a forte in back-end development to deliver strict compliance for every project’s specification. 
          All programs, platforms and portals are strictly tested with utmost adherence to all current regulations while focused on generating cost savings and eradicating product inefficiencies. 
          Skilled in generating clean and robust code that updates all software, code applications and create board reporting to influence senior decision making. 
          Passionate about coding applications and liaising with stakeholders across the organization by advocating for technical expansion in product design, features and aspects to maintain the overall architecture. 
          Productive leader that leads by example that reviews internal processes to mentor and coach the next pillars of foundation for a technological firm.
        </p>
      </>
    ),
  };

  return (
    <>
      <div className="top_author_image">
          <img
          sizes="(max-width: 1400px) 100vw, 1400px"
          srcSet={introContent.imageSrcSet}
          src={introContent.imageSrc}
          alt="about" />
      </div>
      <div className="about_title">
        <h3>{introContent.name}</h3>
        <span>{introContent.designation}</span>
      </div>
      <div className="about_text">{introContent.text}</div>
    </>
  );
};

export default Intro;
